import React from "react";
import SEO from "../components/seo";

const TermsAndConditions = () => {
  return (
    <>
      <SEO title={"Regulamin sklepu"} />
      <p><strong>Regulamin Sklepu Internetowego - www.odbojnice.sklep.pl</strong></p>
      <p><strong>I. Postanowienia ogólne</strong></p>
      <ol type="1">
        <li><p>Niniejszy Regulamin określa ogólne warunki, sposób świadczenia usług drogą elektroniczną i sprzedaży
          prowadzonej za pośrednictwem Sklepu Internetowego <strong>www.odbojnice.sklep.pl</strong>. Sklep prowadzi
          Mariusz Adasiak prowadzący działalność gospodarczą pod firmą Mariusz Adasiak PRZEDSIĘBIORSTWO HANDLOWO -
          USŁUGOWE MARTECH wpisany do rejestru przedsiębiorców Centralnej Ewidencji i Informacji o Działalności
          Gospodarczej prowadzonej przez Ministra Przedsiębiorczości i Technologii pod adresem ul. Kolejowa nr 22,
          58-340 Głuszyca, NIP 8851000346, REGON 020958561, zwany dalej Sprzedawcą.</p></li>
        <li><p>Kontakt ze Sprzedawcą odbywa się poprzez:</p>
          <ol type="a">
            <li><p>adres poczty elektronicznej: kontakt@martech-sg.pl;</p></li>
            <li><p>pod numerem telefonu: +48 600 819 114;</p></li>
            <li><p>formularz kontaktowy.</p></li>
          </ol>
        </li>
        <li><p>Niniejszy Regulamin jest nieprzerwanie dostępny w witrynie internetowej www.odbojnice.sklep.pl, w sposób
          umożliwiający jego pozyskanie, odtwarzanie i utrwalanie jego treści poprzez wydrukowanie lub zapisanie na
          nośniku w każdej chwili.</p></li>
        <li><p>Sprzedawca informuje, że korzystanie z Usług świadczonych drogą elektroniczną może wiązać się z
          zagrożeniem po stronie każdego użytkownika sieci Internet, polegającym na możliwości wprowadzenia do systemu
          teleinformatycznego Klienta szkodliwego oprogramowania oraz pozyskania i modyfikacji jego danych przez osoby
          nieuprawnione. By uniknąć ryzyka wystąpienia zagrożeń w/w Klient powinien stosować właściwe środki techniczne,
          które zminimalizują ich wystąpienie, a w szczególności programy antywirusowe i zaporę sieciową typu
          firewall.</p></li>
      </ol>
      <p><strong>II. Definicje</strong></p>
      <p>Użyte w Regulaminie pojęcia oznaczają:</p>
      <ol type="1">
        <li><p><strong>Dni robocze</strong> – są to dni od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od
          pracy;</p></li>
        <li><p><strong>Klient</strong> – osoba fizyczna, która posiada pełną zdolność do czynności prawnych, osoba
          fizyczna prowadząca działalność gospodarczą, osoba prawna lub jednostka organizacyjna nie będącą osobą prawną,
          której przepisy szczególne przyznają zdolność prawną, która dokonuje Zamówienia w ramach Sklepu Internetowego
          lub korzysta z innych Usług dostępnych w Sklepie Internetowym;</p></li>
        <li><p><strong>Kodeks Cywilny</strong> – ustawa z dnia 23 kwietnia 1964 r. (Dz. U. Nr 16, poz. 93 ze zm.);</p>
        </li>
        <li><p><strong>Konto</strong> – przydzielona danemu Klientowi część Sklepu Internetowego, za pomocą którego
          Klient może dokonywać określonych działań w ramach Sklepu Internetowego;</p></li>
        <li><p><strong>Konsument</strong> – Klient będący konsumentem w rozumieniu art. 22[1] Kodeksu cywilnego;</p>
        </li>
        <li><p><strong>Przedsiębiorca</strong> - Klient będący przedsiębiorcą w rozumieniu art. 43[1] Kodeksu cywilnego;
        </p></li>
        <li><p><strong>Regulamin</strong> – niniejszy dokument;</p></li>
        <li><p><strong>Towar</strong> – produkt prezentowany w Sklepie Internetowym, którego opis jest dostępny przy
          każdym z prezentowanych produktów;</p></li>
        <li><p><strong>Umowa sprzedaży</strong> – Umowa sprzedaży Towarów w rozumieniu Kodeksu Cywilnego, zawarta
          pomiędzy Sprzedawcą a Klientem;</p></li>
        <li><p><strong>Usługi</strong> – usługi świadczone przez Sprzedawcę na rzecz Klientów drogą elektroniczną w
          rozumieniu przepisów ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną (Dz.U. nr 144,
          poz. 1204 ze zm.);</p></li>
        <li><p><strong>Ustawa o prawach konsumenta</strong> – ustawa z dnia 30 maja 2014 r. o prawach konsumenta (Dz. U.
          2014, Nr 827);</p></li>
        <li><p><strong>Ustawa o świadczeniu usług drogą elektroniczną</strong> – ustawa z dnia 18 lipca 2002 r. o
          świadczeniu usług drogą elektroniczną (Dz. U. Nr 144, poz. 1204 ze zm.);</p></li>
        <li><p><strong>Zamówienie</strong> – oświadczenie woli Klienta, zmierzające do zawarcia Umowy
          sprzedaży, określające w szczególności rodzaj i liczbę towaru.</p></li>
      </ol>
      <p><strong>III. Zasady korzystania ze Sklepu Internetowego</strong></p>
      <ol type="1">
        <li><p>Korzystanie ze Sklepu Internetowego jest możliwe pod warunkiem spełnienia przez system teleinformatyczny,
          z którego korzysta Klient, następujących minimalnych wymagań technicznych:</p>
          <ol type="a">
            <li><p>komputer lub urządzenie mobilne z dostępem do Internetu,</p></li>
            <li><p>dostęp do poczty elektronicznej,</p></li>
            <li><p>przeglądarka internetowa Internet Explorer w wersji 11 lub nowszej, Firefox w wersji 28.0 lub
              nowszej, Chrome w wersji 32 lub nowszej, Opera w wersji 12.17 lub nowszej, Safari w wersji 1.1. lub
              nowszej,</p></li>
            <li><p>włączenie w przeglądarce internetowej Cookies oraz Javascript.</p></li>
          </ol>
        </li>
        <li><p>Korzystanie ze Sklepu Internetowego oznacza każdą czynność Klienta, która prowadzi do zapoznania się
          przez niego z treściami zawartymi w Sklepie.</p></li>
        <li><p>Klient zobowiązany jest w szczególności do:</p>
          <ol type="a">
            <li><p>niedostarczania i nieprzekazywania treści zabronionych przez przepisy prawa, np. treści propagujących
              przemoc, zniesławiających lub naruszających dobra osobiste i inne prawa osób trzecich,</p></li>
            <li><p>korzystania ze Sklepu Internetowego w sposób niezakłócający jego funkcjonowania, w szczególności
              poprzez użycie określonego oprogramowania lub urządzeń,</p></li>
            <li><p>niepodejmowania działań takich jak: rozsyłanie lub umieszczanie w ramach Sklepu Internetowego
              niezamówionej informacji handlowej (spam),</p></li>
            <li><p>korzystania ze Sklepu Internetowego w sposób nieuciążliwy dla innych Klientów oraz dla
              Sprzedawcy,</p></li>
            <li><p>korzystania z wszelkich treści zamieszczonych w ramach Sklepu Internetowego jedynie w zakresie
              własnego użytku osobistego,</p></li>
            <li><p>korzystania ze Sklepu Internetowego w sposób zgodny z przepisami obowiązującego na terytorium
              Rzeczypospolitej Polskiej prawa, postanowieniami Regulaminu, a także z ogólnymi zasadami korzystania z
              sieci Internet.</p></li>
          </ol>
        </li>
      </ol>
      <p><strong>IV. Usługi</strong></p>
      <ol type="1">
        <li><p>Sprzedawca umożliwia za pośrednictwem Sklepu Internetowego korzystanie z bezpłatnych Usług, które są
          świadczone przez Sprzedawcę 24 godziny na dobę, 7 dni w tygodniu.</p></li>
        <li><p>Klient ma możliwość wysłania za pomocą formularza zamówienia dostępnego w Sklepie Internetowym
          wiadomości do Sprzedawcy. Umowa o świadczenie Usługi polegającej na udostępnianiu interaktywnego formularza
          kontaktowego jest zawierana na czas oznaczony i ulega rozwiązaniu z chwilą wysłania wiadomości przez Klienta
          za pośrednictwem formularza.</p></li>
        <li><p>Sprzedawca ma prawo do organizowania okazjonalnych konkursów i promocji, których warunki każdorazowo
          zostaną podane na stronach internetowych Sklepu. Promocje w Sklepie Internetowym nie podlegają łączeniu, o ile
          Regulamin danej promocji nie stanowi inaczej.</p></li>
        <li><p>W przypadku naruszenia przez Klienta postanowień niniejszego Regulaminu, Sprzedawca po uprzednim
          bezskutecznym wezwaniu do zaprzestania lub usunięcia naruszeń, z wyznaczeniem stosownego terminu, może
          rozwiązać umowę o świadczenie Usług z zachowaniem 14-dniowego terminu wypowiedzenia.</p></li>
      </ol>
      <p><strong>V. Procedura zawarcia Umowy sprzedaży</strong></p>
      <ol type="1">
        <li><p>Informacje o Towarach podane na stronach internetowych Sklepu, w szczególności ich opisy, parametry
          techniczne i użytkowe oraz ceny, stanowią zaproszenie do zawarcia Umowy, w rozumieniu art. 71 Kodeksu
          Cywilnego.</p></li>
        <li><p>Wszystkie Towary dostępne w Sklepie Internetowym są fabrycznie nowe i zostały legalnie wprowadzone na
          rynek polski.</p></li>
        <li><p>Warunkiem złożenia Zamówienia jest wypełnienie aktywnego formularza zamówienia oraz posiadanie konta poczty elektronicznej.</p></li>
        <li><p>W przypadku składania Zamówienia poprzez formularz Zamówienia dostępny na stronie internetowej Sklepu
          Internetowego, Zamówienie zostaje złożone Sprzedawcy przez Klienta w formie elektronicznej. Zamówienie to nie stanowi oferty
          zawarcia Umowy sprzedaży Towarów będących przedmiotem Zamówienia. Oferta złożona w postaci elektronicznej nie
          wiąże Klienta. Po wysłaniu kompletnego formularza Zamówienia, Sprzedawca przesyła na podany przez Klienta adres poczty elektronicznej potwierdzenie wpłynięcia Zamówienia wraz fakturą pro forma potwierdzającą wartość Zamówienia, koszt dostawy oraz termin realizacji Zamówienia. Z chwilą zaksięgowanie wpłaty Klienta zawarta zostaje Umowa sprzedaży.</p></li>
        <li><p>Złożenie Zamówienia w Sklepie Internetowym za pośrednictwem telefonu lub poprzez przesłanie wiadomości
          elektronicznej następuje w Dniach roboczych oraz godzinach wskazanych na stronie internetowej Sklepu
          Internetowego. W tym celu Klient powinien:</p>
          <ol type="a">
            <li><p>podać podczas rozmowy telefonicznej lub w treści wiadomości elektronicznej kierowanej do Sprzedawcy
              nazwę Towaru spośród Towarów znajdujących się na stronie internetowej Sklepu i jego ilość,</p></li>
            <li><p>wskazać sposób dostawy i formę płatności spośród sposobów dostawy i płatności podanych na stronie
              internetowej Sklepu,</p></li>
            <li><p>podać dane potrzebne do realizacji Zamówienia, a w szczególności: imię i nazwisko, miejsce
              zamieszkania oraz adres e-mail.</p></li>
          </ol>
        </li>
        <li><p>Informacja na temat całkowitej wartości Zamówienia, o którym mowa w pkt powyżej, podawana jest każdorazowo przez Sprzedawcę poprzez poinformowanie w drodze
          wiadomości elektronicznej wraz z farturą pro forma. Zawarcie przez Klienta Umowy sprzedaży następuje po wykonaniu obowiązku zapłaty za zamówiony Towar.</p></li>
        <li><p>Dokonanie zapłaty za zamówiony Towar oznacza akceptacje przez klienta treści Regulaminu i potwierdzenie zapoznania się z pouczeniem o braku możliwości odstąpienia od Umowy.</p></li>
      </ol>
      <p><strong>VI. Dostawa</strong></p>
      <ol type="1">
        <li><p>Klient odbiera Towar w punkcie odbioru osobistego Sprzedawcy.</p></li>
        <li><p>Sprzedawca na życzenie Klienta może wycenić dostawę Towaru za pomocą firmy kurierskiej. Płatność na wysyłkę następuje razem z płatnością za Zamówienie</p></li>
        <li><p>Termin dostawy i realizacji Zamówienia określany jest przez Sprzedawcę w wiadomości potwierdzającej wpłynięcie zamówienia.</p>
        </li>
        <li><p>Sprzedawca dostarcza Klientowi dowód zakupu.</p></li>
        <li><p>Jeżeli dla Towarów objętych Zamówieniem przewidziano różny okres realizacji, dla całego Zamówienia
          obowiązuje okres najdłuższy spośród przewidzianych.</p></li>
      </ol>
      <p><strong>VII. Ceny i metody płatności</strong></p>
      <ol type="1">
        <li><p>Ceny Towarów podawane są w złotych polskich i zawierają wszystkie składniki, w tym podatek VAT, cła oraz
          inne opłaty.</p></li>
        <li><p>Sprzedawca poprzez zapis "Termin płatności" na dokumencie faktura pro forma informuje Klientanta o terminie w jakim jest on zobowiązany
          dokonać płatności za Zamówienie. W przypadku braku płatności przez Klienta w terminie, Zamówienie zostanie anulowane.</p></li>
      </ol>
      <p><strong>VIII. Uprawnienie do odstąpienia od Umowy</strong></p>
      <ol type="1">
        <li><p>Prawo do odstąpienia od Umowy przez Konsumenta jest wyłączone na podstawie zawarcia umowy, w której przedmiotem świadczenia jest Towar nieprefabrykowany, wyprodukowany według specyfikacji Konsumenta lub służący zaspokojeniu jego zindywidualizowanych potrzeb;</p></li>
      </ol>
      <p><strong>IX. Reklamacje dotyczące Towarów z tytułu rękojmi</strong></p>
      <ol type="1">
        <li><p>Sprzedawca zobowiązuje się dostarczyć Towar bez wad.</p></li>
        <li><p>Sprzedawca odpowiada wobec Klienta, w tym również Klienta będącego Konsumentem, z tytułu rękojmi za wady
          na zasadach określonych w art. 556 – 576 Kodeksu Cywilnego.</p></li>
        <li><p>Reklamacje, wynikające z naruszenia praw Klienta gwarantowanych prawnie lub na podstawie niniejszego
          Regulaminu, należy kierować na adres Mariusz Adasiak PRZEDSIĘBIORSTWO HANDLOWO - USŁUGOWE MARTECH, ul.
          Kolejowa 22, 58-340 Głuszyca, na adres poczty elektronicznej: kontakt@martech-sg.pl, numer telefonu +48 600
          819 114.</p></li>
        <li><p>Celem rozpatrzenia reklamacji Klient powinien przesłać lub dostarczyć reklamowany Towar, jeżeli jest to
          możliwe dołączając do niego dowód zakupu. Towar należy dostarczyć lub przesłać na adres wskazany w pkt. 3.</p>
        </li>
        <li><p>Sprzedawca zobowiązuje się do rozpatrzenia każdej reklamacji w terminie do 14 dni.</p></li>
        <li><p>W przypadku braków w reklamacji Sprzedawca wezwie Klienta do jej uzupełnienia w niezbędnym zakresie
          niezwłocznie, nie później jednak niż w terminie 7 dni, od daty otrzymania wezwania przez Klienta.</p></li>
      </ol>
      <p><strong>X. Reklamacje w zakresie świadczenia usług drogą elektroniczną</strong></p>
      <ol type="1">
        <li><p>Klient może zgłaszać Sprzedawcy reklamacje w związku z funkcjonowaniem Sklepu i korzystaniem z Usług.
          Reklamacje można zgłaszać pisemnie na adres: Mariusz Adasiak PRZEDSIĘBIORSTWO HANDLOWO - USŁUGOWE MARTECH, ul.
          Kolejowa 22, 58-340 Głuszyca, na adres poczty elektronicznej: kontakt@martech-sg.pl, numer telefonu +48 600
          819 114.</p></li>
        <li><p>W reklamacji Klient powinien podać swoje imię i nazwisko, adres do korespondencji, rodzaj i opis
          zaistniałego problemu.</p></li>
        <li><p>Sprzedawca zobowiązuje się do rozpatrzenia każdej reklamacji w terminie do 14 dni, a gdyby to nie było
          możliwe, do poinformowania w tym okresie Klienta, kiedy reklamacja zostanie rozpatrzona. W przypadku braków w
          reklamacji Sprzedawca wezwie Klienta do jej uzupełnienia w niezbędnym zakresie w terminie 7 dni, od daty
          otrzymania wezwania przez Klienta.</p></li>
      </ol>
      <p><strong>XI. Pozasądowe sposoby rozstrzygania reklamacji i dochodzenia roszczeń</strong></p>
      <ol type="1">
        <li><p>Klient będący Konsumentem posiada m.in. następujące możliwości skorzystania z pozasądowych sposobów
          rozpatrywania reklamacji i dochodzenia roszczeń:</p>
          <ol type="a">
            <li><p>jest uprawniony do zwrócenia się do stałego polubownego sądu konsumenckiego działającego przy
              Inspekcji Handlowej z wnioskiem o rozstrzygnięcie sporu wynikłego z zawartej Umowy sprzedaży;</p></li>
            <li><p>jest uprawniony do zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej z wnioskiem o
              wszczęcie postępowania mediacyjnego w sprawie polubownego zakończenia sporu między Klientem a
              Sprzedawcą;</p></li>
            <li><p>może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu między Klientem a Sprzedawcą,
              korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika konsumentów lub organizacji
              społecznej, do której zadań statutowych należy ochrona Konsumentów (m.in. Federacja Konsumentów,
              Stowarzyszenie Konsumentów Polskich). Porady udzielane są pod przez Federację Konsumentów pod bezpłatnym
              numerem infolinii konsumenckiej 800 007 707 oraz przez Stowarzyszenie Konsumentów Polskich pod adresem
              email porady@dlakonsumentow.pl;</p></li>
            <li><p>złożyć swoją skargę za pośrednictwem unijnej platformy internetowej ODR, dostępnej pod adresem:
              http://ec.europa.eu/consumers/odr/.</p></li>
          </ol>
        </li>
      </ol>
      <p><strong>XII. Ochrona danych osobowych</strong></p>
      <blockquote>
        <p>Podane przez Klientów dane osobowe Sprzedawca zbiera i przetwarza zgodnie z obowiązującymi przepisami prawa
          oraz zgodnie z Polityką Prywatności, dostępną na stronie Sklepu.</p>
      </blockquote>
      <p><strong>XIII. Postanowienia końcowe</strong></p>
      <ol type="1">
        <li><p>Wszelkie prawa do Sklepu Internetowego, w tym majątkowe prawa autorskie, prawa własności intelektualnej
          do jego nazwy, domeny internetowej, strony internetowej Sklepu Internetowego, a także do formularzy, logotypów
          należą do Sprzedawcy, a korzystanie z nich może następować wyłącznie w sposób określony i zgodny z
          Regulaminem.</p></li>
        <li><p>Rozstrzyganie ewentualnych sporów powstałych pomiędzy Sprzedawcą a Klientem, który jest Konsumentem,
          zostaje poddane sądom właściwym zgodnie z postanowieniami właściwych przepisów Kodeksu postępowania
          cywilnego.</p></li>
        <li><p>Rozstrzyganie ewentualnych sporów powstałych pomiędzy Sprzedawcą a Klientem, który jest Przedsiębiorcą
          zostaje poddane sądowi właściwemu ze względu na siedzibę Sprzedawcy.</p></li>
        <li><p>W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy Kodeksu Cywilnego,
          przepisy Ustawy o świadczeniu usług drogą elektroniczną, przepisy Ustawy o prawach Konsumenta oraz inne
          właściwe przepisy prawa polskiego.</p></li>
        <li><p>O wszelkich zmianach niniejszego Regulaminu każdy Klient zostanie poinformowany poprzez informacje na
          stronie głównej Sklepu Internetowego zawierającej zestawienie zmian i termin ich wejścia w życie. Klienci
          posiadający Konto zostaną dodatkowo poinformowani o zmianach wraz z ich zestawieniem na wskazany przez nich
          adres poczty elektronicznej. Termin wejścia w życie zmian nie będzie krótszy niż 14 dni od dnia ich
          ogłoszenia. W razie, gdy Klient posiadający Konto Klienta, nie akceptuje nowej treści Regulaminu obowiązany
          jest zawiadomić o tym fakcie Sprzedawcę w ciągu 14 dni od daty poinformowania o zmianie Regulaminu.
          Zawiadomienie Sprzedawcy o braku akceptacji nowej treści Regulaminu skutkuje rozwiązaniem Umowy.</p></li>
      </ol>
    </>
  );
}

export default TermsAndConditions;
